@tailwind base;
@layer base {
    ol,
    ul {
        list-style: initial;
        margin: revert;
        padding: revert;
    }
}
@tailwind components;
@tailwind utilities;

body {
    @apply bg-lightGray-normal;
}

* {
    max-width: 100vw !important;
    scroll-behavior: smooth;
}

.awssld__content img {
    @apply object-contain max-h-full !important;
}

.awssld *:not(.awssld__bullets *) {
    @apply max-h-[70vh] bg-inherit !important;
}

.ombra {
    text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
        -1px -1px 0 #000;
}

.carousel__controls {
    margin-top: 5rem !important;
}

.carousel__slide:hover .carousel__slide-overlay {
    opacity: 0 !important;
    /* display: none!important; */
    z-index: 50;
}

@media screen and (min-width: 768px) {
    .carousel {
        scale: 0.75 !important;
    }
}

a {
    @apply text-red-500 hover:text-red-600 transition-colors;
}

.carousel__slide {
    cursor: pointer;
}

/* Burger menu */

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    @apply top-10 md:top-16 right-4 md:right-9;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    /* background: #373a47; */
    @apply bg-[#373a47] dark:bg-white;
    /* background: #373a47; */
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    @apply bg-[#a90000] dark:bg-gray-100;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    /* DEBUG */
    /* top: 0; */
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    height: auto !important;
    margin-bottom: 2rem;
    color: #b8b7ad;
    padding: 0.8em;
    @apply flex flex-col gap-3;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}


[data-testid="flowbite-avatar-img"] {
    @apply object-cover
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* set currentColor background to checkboxes */
input[type='checkbox']:checked, input[type='radio']:checked, .dark input[type='checkbox']:checked, .dark input[type='radio']:checked {
    background-color: currentColor!important;
}

div[data-testid='flowbite-tooltip'] ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

video {
    max-height: inherit;
}

nav ul {
    @apply list-none;
}

.markdown * {
    font-size: revert!important;
    margin: revert!important;
    padding: revert!important;
    list-style: revert!important;

    @apply dark:text-gray-300;
}

.markdown h1, .markdown h2, .markdown h3, .markdown h4, .markdown h5, .markdown h6 {
    @apply dark:text-white;
}

.markdown-2 * {
    font-size: revert!important;
    list-style: revert!important;

    @apply dark:text-gray-200;
}

div.input-large div input {
    @apply text-black dark:text-white dark:bg-gray-700 p-2;
}

.leaflet-container {
    @apply w-[80vw] md:w-full h-[60vh] md:h-[80vh] mx-auto z-10;
}

/* fade-out must be animation where element is faded out (then remains invisible) */
.fade-out {
    animation: fadeOut 0.3s forwards !important;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.sticky-container {
    position: relative; /* Assicurati che l'elemento genitore abbia una posizione relativa o assoluta */
  }
  
  .sticky-content {
    position: sticky;
    top: 0; /* Posizione in cui l'elemento diventerà "sticky" */
    z-index: 10; /* Assicurati che l'elemento "sticky" sia sopra altri elementi */
    background-color: white; /* Assicurati che l'elemento "sticky" abbia uno sfondo visibile */
    padding: 10px; /* Aggiungi del padding per una migliore visualizzazione */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Aggiungi un'ombra per separare l'elemento "sticky" dal resto del contenuto */
  }